import React from 'react';
import { motion } from 'framer-motion';

function Header() {
  return (
    <motion.header className="bg-white shadow-md" initial={{ y: -100 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>
      <div className="container mx-auto p-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>BROKKOLI.PRO</h1>
        <nav>
          <ul className="flex space-x-4">
            <li><a href="#hero" className="hover:text-blue-500 transition duration-300" style={{ color: 'rgb(72, 139, 70)' }}>Главная</a></li>
            <li><a href="#services" className="hover:text-blue-500 transition duration-300" style={{ color: 'rgb(72, 139, 70)' }}>Услуги</a></li>
            <li><a href="#about" className="hover:text-blue-500 transition duration-300" style={{ color: 'rgb(72, 139, 70)' }}>О нас</a></li>
            <li><a href="#contact" className="hover:text-blue-500 transition duration-300" style={{ color: 'rgb(72, 139, 70)' }}>Контакты</a></li>
          </ul>
        </nav>
      </div>
    </motion.header>
  );
}

export default Header;