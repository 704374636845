import React from 'react';
import { motion } from 'framer-motion';

function Hero() {
  return (
    <section id="hero" className="bg-gray-100 py-20">
      <div className="container mx-auto text-center">
        <motion.h1 className="text-4xl font-bold" style={{ color: 'rgb(72, 139, 70)' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          Добро пожаловать в BROKKOLI.PRO
        </motion.h1>
        <motion.p className="text-gray-600 mt-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.5 }}>
          Ваш надежный партнер в мире консалтинга и недвижимости.
        </motion.p>
        <motion.a href="#services" className="mt-8 inline-block text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300" style={{ backgroundColor: 'rgb(72, 139, 70)' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 1 }}>
          Узнать больше
        </motion.a>
      </div>
    </section>
  );
}

export default Hero;