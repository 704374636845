import React from 'react';

function Footer() {
  return (
    <footer className="bg-white py-6">
      <div className="container mx-auto text-center">
        <p className="text-gray-600" style={{ color: 'rgb(72, 139, 70)' }}>© 2023 BROKKOLI.PRO. Все права защищены.</p>
      </div>
    </footer>
  );
}

export default Footer;