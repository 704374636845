import React from 'react';
import { motion } from 'framer-motion';

const aboutVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

function AboutUs() {
  return (
    <section id="about" className="py-20 bg-white">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>О нас</h2>
        <p className="text-gray-600 mt-4">Мы - команда профессионалов, готовых помочь вам достичь успеха в бизнесе и недвижимости.</p>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div className="bg-white p-6 rounded-lg shadow-md" variants={aboutVariants} initial="hidden" animate="visible">
            <h3 className="text-2xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Наша миссия</h3>
            <p className="text-gray-600 mt-4">Мы стремимся предоставить нашим клиентам лучшие решения для их бизнеса и недвижимости.</p>
          </motion.div>
          <motion.div className="bg-white p-6 rounded-lg shadow-md" variants={aboutVariants} initial="hidden" animate="visible" transition={{ delay: 0.2 }}>
            <h3 className="text-2xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Наши ценности</h3>
            <p className="text-gray-600 mt-4">Мы ценим честность, прозрачность и профессионализм в нашей работе.</p>
          </motion.div>
          <motion.div className="bg-white p-6 rounded-lg shadow-md" variants={aboutVariants} initial="hidden" animate="visible" transition={{ delay: 0.4 }}>
            <h3 className="text-2xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Наша команда</h3>
            <p className="text-gray-600 mt-4">Наши специалисты обладают многолетним опытом и глубокими знаниями в своей области.</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;