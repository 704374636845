import React from 'react';
import { motion } from 'framer-motion';

const serviceVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

function Services() {
  return (
    <section id="services" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Наши услуги</h2>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div className="bg-white p-6 rounded-lg shadow-md" variants={serviceVariants} initial="hidden" animate="visible">
            <h3 className="text-2xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Консалтинг</h3>
            <p className="text-gray-600 mt-4">Профессиональные консультации по развитию бизнеса.</p>
          </motion.div>
          <motion.div className="bg-white p-6 rounded-lg shadow-md" variants={serviceVariants} initial="hidden" animate="visible" transition={{ delay: 0.2 }}>
            <h3 className="text-2xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Анализ рынка</h3>
            <p className="text-gray-600 mt-4">Глубокий анализ рынка и конкурентной среды.</p>
          </motion.div>
          <motion.div className="bg-white p-6 rounded-lg shadow-md" variants={serviceVariants} initial="hidden" animate="visible" transition={{ delay: 0.4 }}>
            <h3 className="text-2xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Стратегическое планирование</h3>
            <p className="text-gray-600 mt-4">Разработка стратегий для достижения ваших целей.</p>
          </motion.div>
          <motion.div className="bg-white p-6 rounded-lg shadow-md" variants={serviceVariants} initial="hidden" animate="visible" transition={{ delay: 0.6 }}>
            <h3 className="text-2xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Купля-Продажа недвижимости</h3>
            <p className="text-gray-600 mt-4">Полное сопровождение сделок по купле-продаже недвижимости, от поиска объекта до оформления документов.</p>
          </motion.div>
          <motion.div className="bg-white p-6 rounded-lg shadow-md" variants={serviceVariants} initial="hidden" animate="visible" transition={{ delay: 0.8 }}>
            <h3 className="text-2xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Аренда</h3>
            <p className="text-gray-600 mt-4">Помощь в поиске и аренде коммерческой и жилой недвижимости, а также юридическое сопровождение сделок.</p>
          </motion.div>
          <motion.div className="bg-white p-6 rounded-lg shadow-md" variants={serviceVariants} initial="hidden" animate="visible" transition={{ delay: 1 }}>
            <h3 className="text-2xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Составление Договоров</h3>
            <p className="text-gray-600 mt-4">Профессиональное составление и проверка договоров, чтобы защитить ваши интересы и минимизировать риски.</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default Services;