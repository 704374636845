import React from 'react';
import { motion } from 'framer-motion';

function Contact() {
  return (
    <section id="contact" className="py-20 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold" style={{ color: 'rgb(72, 139, 70)' }}>Контакты</h2>
        <p className="text-gray-600 mt-4">Свяжитесь с нами для получения дополнительной информации.</p>
        <motion.a href="mailto:info@brokkoli.pro" className="mt-8 inline-block text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300" style={{ backgroundColor: 'rgb(72, 139, 70)' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.5 }}>
          Написать нам
        </motion.a>
      </div>
    </section>
  );
}

export default Contact;